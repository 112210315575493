import React from 'react'
import {
  CommonBanner,
  Container,
  Link,
  Section,
  Seo,
} from '@components'
import { QuickstartGrid } from '../../../components/site/quickstarts'
import { ResourceProvider } from '../../../components/site/resources'
import { PageLayout } from '../../../components/site/layout/page-layout'

const QuickstartsPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      <CommonBanner title="Quickstarts" />

      <ResourceProvider>
        <Section className='py-[6rem]'>
          <Container className="scroll-mt-32 text-center sm:text-left" id="mobile-app-quickstarts">
            <h2 className="mt-0 mb-6 text-3xl">Mobile App Quickstarts</h2>
            <p>
              Integrating Approov into mobile applications is straightforward,
              and quickstart guides are provided for popular platforms below. If
              your platform is not listed, a generic integration approach is
              described in the{' '}
              <Link href="https://approov.io/docs/latest/approov-direct-sdk-integration/">
                Approov Direct SDK Integration Guide
              </Link>{' '}
              or <Link href="https://approov.io/info/contact">Contact Us</Link>.
            </p>

            <QuickstartGrid tag="mobile" />
          </Container>

          <Container className="scroll-mt-32 text-center sm:text-left" id="backend-api-quickstarts">
            <h2 className="mt-12 mb-6 text-3xl">Backend API Quickstarts</h2>
            <p>
              Backend API integration is only necessary if you have your own API
              backend and are using Approov tokens. If you are using Approov to
              protect API keys using Runtime Secrets Protection then no backend
              API integration is needed at all. Integrating Approov into backend
              services is straightforward, and quickstart guides are provided
              for popular platforms below. If your platform is not listed, see{' '}
              <Link href="https://approov.io/docs/latest/approov-usage-documentation/#backend-integration">
                Backend Integration
              </Link>{' '}
              or <Link href="https://approov.io/info/contact">Contact Us</Link>.
            </p>

            <QuickstartGrid tag="backend" />
          </Container>

          <Container className="scroll-mt-32 text-center sm:text-left" id="web-protection-quickstarts">
            <h2 className="mt-12 mb-6 text-3xl">Web Protection Quickstarts</h2>
            <p>
              Integrating Approov with web application protections is
              straightforward, and quickstart guides are provided for popular
              approaches. More information is provided in{' '}
              <Link href="https://approov.io/docs/latest/approov-web-protection-integration">
                Web Protection Integration
              </Link>
              .
            </p>

            <QuickstartGrid tag="web" />
          </Container>
        </Section>
      </ResourceProvider>
    </PageLayout>
  )
}

export default QuickstartsPage
